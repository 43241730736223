html {
	position: relative;
	min-height: 100%;
	padding: 0;
	margin: 0;
}

body {
	margin: 0;
	padding: 0;
	font-family: helvetica;
	font-weight: 400;
	background-color: white;
	color: #22272b;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'eloquent_jf_pro';
}

* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

*:before,
*:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.embed-container-home {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;
}

.embed-container-home iframe,
.embed-container-home object,
.embed-container-home embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/*
*
* ANIMATIONS
*
*/

@-webkit-keyframes loading {
	to {
		-webkit-transform: rotate(360deg);
	}
}

@-moz-keyframes loading {
	to {
		-moz-transform: rotate(360deg);
	}
}

@-ms-keyframes loading {
	to {
		-ms-transform: rotate(360deg);
	}
}

@keyframes loading {
	to {
		transform: rotate(360deg);
	}
}

.fade-enter {
	opacity: 0;
}

.fade-enter.fade-enter-active {
	opacity: 1;
	transition: opacity 500ms ease-in;
}

.fade-exit,
.fade-exit.fade-exit-active {
	display: none;
}

/*
*
*
* TYPOGRAPHY
*
*/

@font-face {
	font-family: 'eloquent_jf_pro';
	src: url('./fonts/eloquent_jf_pro-webfont.woff2') format('woff2'),
		url('./fonts/eloquent_jf_pro-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'travelingtypewriter';
	src: url('./fonts/travelingtypewriter-webfont.woff2') format('woff2'),
		url('./fonts/travelingtypewriter-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

/*
*
* TYPIST
*
*/

.Typist .Cursor {
	display: inline-block;
}

.Typist .Cursor--blinking {
	opacity: 1;
	animation: blink 1s linear infinite;
}

@keyframes blink {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
